import * as React from 'react';
import { Link } from 'react-router-dom';
import CoreApi from '../services/coreApi';

export default class Home extends React.Component {
    searchDebounce = null;

    state = {
        searchTerm: '',
        searchResults: []
    };

    constructor(props) {
        super(props);

        this.search = this.search.bind(this);
    }

    render() {
        return <div className={this.getSearchClassNames()}>
            <input placeholder="Search anime by series name" value={this.state.searchTerm} onChange={e => this.search(e.currentTarget.value)} />
            <ul className="searchResults">
                {this.state.searchResults.map(series => (
                    <li key={series.id}>
                        <Link to={`series/${series.id}`}>
                            <img src={series.image} />
                            <span>{series.name}</span>
                        </Link>
                    </li>
                ))}
            </ul>

        </div>;
    }

    search(term) {
        this.setState({searchTerm: term});
                
        clearTimeout(this.searchDebounce);

        this.searchDebounce = setTimeout(() => {
            if (term == '') {
                this.setState({searchResults: []});
                return;
            }

            CoreApi.search(term)
            .then(data => {
                this.setState({searchResults: data});
            })

        }, 500);

    }

    getSearchClassNames() {
        let classes = ['search'];
        if (this.state.searchTerm != '' && this.state.searchResults.length > 0) {
            classes.push('hasResults');
        }

        return classes.join(' ');
    }
}