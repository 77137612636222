export default class CoreApi {
    static baseUrl = `${window.location.protocol}//${window.location.host}/api/anime`;

    static search(term) {
        return fetch(`${CoreApi.baseUrl}/search/${term}`, {
                headers: { 'Accepts': 'application/json'}
            })
            .then(res => res.json());
    }

    static getSeries(series) {
        return fetch(`${CoreApi.baseUrl}/${series}`, {
            headers: { 'Accepts': 'application/json'}
        })
        .then(res => res.json());
    }

    static getEpisode(series, episode) {
        return fetch(`${CoreApi.baseUrl}/${series}/episodes/${episode}`, {
            headers: { 'Accepts': 'application/json'}
        })
        .then(res => res.json());
    }

    static getEpisodeVideo(host, video) {
        return fetch(`${CoreApi.baseUrl}/video/${host}/${video}`, {
            headers: { 'Accepts': 'application/json'}
        })
        .then(res => res.json());
    }
}