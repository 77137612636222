import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Series from './components/series';

function App() {
  return (
    <Routes>
      <Route path="/" exact={true} element={<Home />} />
      <Route path="/series/:series" element={<Series />} />
      <Route path="/series/:series/:episode" element={<Series />} />
    </Routes>
  );
}

export default App;
