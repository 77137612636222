import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as moment from 'moment';
import { VscMenu } from 'react-icons/vsc';
import CoreApi from '../services/coreApi';

export default function Series() {
    const {series, episode} = useParams();
    const [seriesEpisodes, setSeriesEpisodes ] = useState([]);
    const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
    const [playbackUrl, setPlaybackUrl] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        CoreApi.getSeries(series)
        .then(data => setSeriesEpisodes(data));
        
    }, [series]);

    useEffect(() => {
        if (typeof episode != 'undefined') {
            CoreApi.getEpisode(series, episode)
            .then(data => {
                setCurrentlyPlaying(data);
                setPlaybackUrl(null);
                CoreApi.getEpisodeVideo(data.videos[0].host, data.videos[0].id)
                .then(data => setPlaybackUrl(data.url));
            });
        }
        else {
            setCurrentlyPlaying(null);
        }
    }, [episode]);

    return <div className={`series ${currentlyPlaying != null ? 'now-playing' : ''}`}>
        <div className="series-navbar">
            <a className="series-episode-toggle" onClick={() => setIsMenuOpen(val => !val)}><VscMenu /></a>
            
            <h4>Episodes</h4>
        </div>
        <div className={`series-episodes-container ${isMenuOpen ? 'open' : ''}`}>
            <ul className="series-episodes">
            {seriesEpisodes.map(e => (
                <li key={e.id}>
                    <Link to={`/series/${series}/${e.id}}`}>
                        Episode&nbsp;{e.episode}<br/>
                        <small>{moment(e.dateAired).fromNow()}</small>
                    </Link>
                </li>
            ))}
            </ul>
        </div>

        {currentlyPlaying != null ? <div className="series-content">
            <h3>{currentlyPlaying.name.default}</h3>
            <iframe src={`//${currentlyPlaying.videos[0].host}/embed/${currentlyPlaying.videos[0].id}`} style={{ position: 'absolute', left: '-9999px' }}/>
            <div className="player">
            {
            playbackUrl != null 
            ?   <video controls autoPlay className="player-iframe" >
                    <source src={playbackUrl} type="video/mp4" />
                </video>

            :   <h1>Loading</h1>
            }
            </div>
        </div> : null}
    </div>;
}